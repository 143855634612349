<template>
  <el-dialog v-model="state.isshow" title="员工调动" width="20%" :before-close="handleClose">
    <div>调入部门：</div>
    <div class="mt-10">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
          class="demo-ruleForm w100" label-position="left">
          <el-form-item label="部门" prop='deptId'>
            <el-tree-select v-model="ruleForm.deptId" :data="state.deptData" :render-after-expand="false" check-strictly=true placeholder="请选择部门"></el-tree-select>
          </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="dp-f jc-fe">
        <oabutton title="取消" CSStype="3" width="100" class="searcMR" @buttonclick="state.isshow=false"></oabutton>
        <oabutton title="确定" CSStype="2" width="100" @buttonclick="submitForm()" v-model:loading="dialogloading"></oabutton>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref,unref,defineEmits } from "vue";
import { httpToken } from "@/utils/request";
import qs from "qs";
const emit = defineEmits(['Emit'])
const dialogloading = ref(false);// 弹框按钮loading 显示
const state = reactive({
  isshow:false,
  deptData: [], //部门下拉数据
  ids:[],//当前操作的用户id
});
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
    deptId:'',//所属部门id
})
const rules = reactive({//from 表单校验
  deptId: [
    { required: true, message: '请输入部门', trigger: 'blur' },
  ],
})
const show=((ids,deptData)=>{
  ruleForm.deptId=''
  state.ids=ids
  state.deptData=deptData
  state.isshow=true
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      httpToken({
        method: "post",
        url: '/admin/sys-user/move',
        data:qs.stringify({
          ids:JSON.stringify(state.ids),
          deptId :ruleForm.deptId
        }),
      }).then((res) => {
        dialogloading.value = false
        state.isshow=false
        emit('Emit')
      }).catch(() => {
        dialogloading.value = false
      })
    } else {
      dialogloading.value = false
    }
  })
}
defineExpose({
    show
});
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label{
  display: none;
}
</style>
