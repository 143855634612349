<template>
  <el-drawer v-model="state.isshow" size="25%">
    <template #header="{ close, titleId, titleClass }">
      <div class="application">员工详情</div>
      <div @click="emit('editEmit',state.row)" class="cursorPo" v-permission="'role_enterpriseMembers:edit'">编辑</div>
      <el-popover :width="50" trigger="click">
        <template #reference>
          <div class="ml-20 mr-20 cursorPo" v-permission="'role_enterpriseMembers:edit'">更多</div>
        </template>
        <template #default>
          <div class="demo-rich-conent" style="display: flex; gap: 16px; flex-direction: column">
            <div @click="emit('delEmit',[state.row.userId])" class="cursorPo" v-permission="'role_enterpriseMembers:del'">移除员工</div>
            <div @click="emit('transferEmit',[state.row.userId])" class="cursorPo">员工调动</div>
          </div>
        </template>
      </el-popover>
    </template>
    <div class="details ">
      <div class="dp-f ai-c ">
        <div>
          <img src="@/assets/img/role/detailsRole.png" class="w-48 h-48 " alt="" />
        </div>
        <div class=" ml-12">
          <div class="fontSize fs-20">{{state.row.chnname}}</div>
          <div class="subFont fs-16">{{state.row.phone}} ｜ {{getisCertificationStatus(state.row.isCertification)}}</div>
        </div>
      </div>
    </div>
    <div class="dp-f mt-20">
      <div class="company fs-16">公司/部门：</div>
      <div class="describe fs-16">{{userCompanyInfo.companyName}}/{{state.row.deptName}}</div>
    </div>
    <div class="dp-f mt-20">
      <div class="company fs-16">岗位：</div>
      <div class="describe fs-16">
        {{ state.row.postStr }}
        <!-- <span v-for="(el,ind) in state.row.postList" :key="ind"><span v-if="ind!=0">、</span>{{ el.postName }}</span> -->
      </div>
    </div>
    <div class="dp-f mt-20">
      <div class="company fs-16">成员编号：</div>
      <div class="describe fs-16">{{state.row.memberNumber}}</div>
    </div>
    <div class="dp-f mt-20">
      <div class="company fs-16">平台角色：</div>
      <div class="describe fs-16">
        <span v-for="(el,ind) in state.row.roleList" :key="ind"><span v-if="ind!=0">、</span>{{ el.roleName }}</span>
      </div>
    </div>
  </el-drawer>
</template>

<script setup>
import { reactive, ref,computed,defineEmits } from "vue";
import { getisCertificationStatus } from "@/utils/server/getcode.js"
const emit = defineEmits(['editEmit','delEmit','transferEmit'])
// 当前登录企业信息
const userCompanyInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info.sysCompany
})
const state = reactive({
  isshow:false,
  nowDept:[],//当前选择部门数据
  row:[],//当前选择用户数据
});
const show=((nowDept,row)=>{
  state.isshow=true
  state.nowDept=nowDept
  state.row=row
})
const colse=(()=>{
  state.isshow=false
})
defineExpose({
  show,
  colse
});
</script>

<style lang="scss" scoped>
.application {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.details {
  height: 70px;
  padding: 11px 16px;
  width: 100%;
  background: url("../../../../../assets/img/role/detailsIcon.png") no-repeat;
  background-size: 100% 100%;
}

.fontSize {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.subFont {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.company {
  width: 24%;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.describe {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.cursorPo {
  cursor: pointer;
}
</style>
