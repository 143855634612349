<template>
  <tablebox v-loading="loading" :element-loading-text="config.loading.text"
    :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
    <div class="dp-f">
      <div class="w-400 fs-0 bg-fff">
        <treeDept ref="treeDeptRef" @DeptChange="DeptChange" @getnewDeptData="getnewDeptData"></treeDept>
      </div>
      <tableLayout v-loading="state.loading" :element-loading-text="config.loading.text"
        :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
        <div class="ml-12 centerTop">
          <rightBar :title="userCompanyInfo.companyName" :subtitle="state.subtitle"></rightBar>
          <el-space wrap class="mt-20">
            <oabutton title="添加部门" CSStype="2" width="100" v-permission="'role_enterpriseMembers:add'"
              @buttonclick="unref(treeDeptRef).newDept()"></oabutton>
            <oabutton title="添加成员" CSStype="2" width="100" v-permission="'role_enterpriseMembers:add'"
              @buttonclick="unref(operationRef).add(state.nowDept.id)"></oabutton>
            <oabutton title="批量导入" CSStype="2" width="100" v-permission="'role_enterpriseMembers:add'"
              @buttonclick="unref(batchRef).add()"></oabutton>
            <!-- <oabutton title="申请记录" CSStype="1" width="100" class="searcMR"
              @buttonclick="unref(applyRecordRef).show()" v-permission="'role_enterpriseMembers:record'">
            </oabutton> -->
          </el-space>
        </div>
        <div class=" mt-12 ml-12 centerbox autobox">
          <div class="dp-f jc-sb">
            <div class="dp-f">
              <div class="input_box w-200">
                <el-input class="input search_input" placeholder="请输入搜索关键字" v-model="state.searchKey.username"
                  :maxlength="config.inputMaxlength" show-word-limit>
                  <template #append>
                    <img src="@/assets/img/general/search.png" class="w-16 h-16 cu-p" @click="searchClick">
                  </template>
                </el-input>
              </div>
              <oabutton width='80' height='40' title="搜索" CSStype=2 style="border-radius:4px;" class="ml-12"
                @buttonclick="searchClick()"></oabutton>
            </div>
            <div class="dp-f">
              <oabutton :disabled="state.selectionData.length == 0" title="移除员工" CSStype="1" width="100"
                @buttonclick="unref(delconfirmRef).show(undefined, undefined, selectionids)"
                v-permission="'role_enterpriseMembers:del'"></oabutton>
              <oabutton :disabled="state.selectionData.length == 0" title="员工调动" CSStype="1" width="100" class="ml-20"
                @buttonclick="unref(transferStaffRef).show(selectionids, state.deptData)"
                v-permission="'role_enterpriseMembers:edit'"></oabutton>
            </div>
          </div>
          <div class="tablebox pt-20">
            <el-table :data="state.Notice" class="w100" :header-cell-style="{
              background: 'var(--active-alpha)',
              color: config.table.color,
              fontWeight: config.table.fontWeight,
              fontSize: config.table.fontSize,
            }" @row-click="rowClick" @selection-change="selectionChange">
              <el-table-column :align="'center'" type="selection" width="55" />
              <el-table-column :align="'center'" prop="chnname" label="姓名" />
              <el-table-column :align="'center'" prop="phone" label="手机号" />
              <el-table-column :align="'center'" prop="memberNumber" label="成员编号" />
              <el-table-column :align="'center'" prop="role" label="角色">
                <template #default="scope">
                  <div class="t-oh">
                    <el-tag class="mr-5 ml-5 mb-5 mt-5" v-for="(el, ind) in scope.row.roleList" :key="ind">{{ el.roleName
                    }}</el-tag>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </tableLayout>
    </div>
    <applyRecord ref="applyRecordRef"></applyRecord>
    <transferStaff ref="transferStaffRef" @Emit="getemitnew"></transferStaff>
    <detailsRow ref="detailsRowRef" @editEmit="unref(operationRef).editclick($event, state.nowDept.id)"
      @delEmit="unref(delconfirmRef).show(undefined, undefined, $event)"
      @transferEmit="unref(transferStaffRef).show($event, state.deptData)"></detailsRow>
    <delconfirm ref="delconfirmRef" @delEmit="delExecute"></delconfirm>
    <operation ref="operationRef" @Emit="getemitnew"></operation>
    <batch ref="batchRef" @Emit="batchData"></batch>
    <confirm ref="confirmRef" @Emit="getemitnew"></confirm>
  </tablebox>
</template>
<script setup>
import { reactive, ref, unref, computed } from "vue";
import treeDept from "./components/treeDept.vue";//树型部门组件
import rightBar from "../views/components/rightBar/index.vue";//头标题组件
import applyRecord from "./components/user/applyRecord.vue";//申请记录弹出框
import detailsRow from "./components/user/detailsRow.vue";//详情弹出框
import transferStaff from "./components/user/transferStaff.vue";//员工调动弹出框
import delconfirm from "@/components/dialog/delconfirm.vue";//删除确认框
import operation from "./components/user/operation.vue";//用户操作框
import batch from "./components/user/batch.vue"//批量导入弹出框
import confirm from "./components/user/confirm.vue"//批量导入确认弹出框
import { httpToken } from "@/utils/request";
import { checkisnull } from "@/utils/server/check";
import qs from "qs";
const loading = ref(false); //loading 显示
const id = ref(0); //操作的id
const applyRecordRef = ref()//申请记录弹出框ref
const delconfirmRef = ref()// 删除确认框ref
const operationRef = ref();//单用户操作弹出框ref
const transferStaffRef = ref();//员工调动ref
const batchRef = ref();//批量导入弹出框ref
const confirmRef = ref();//批量导入确认弹出框ref
const detailsRowRef = ref();//详情弹出框ref
const treeDeptRef = ref()//树型ref
// 当前登录企业信息
const userCompanyInfo = computed(() => {
  return JSON.parse(window.sessionStorage.user).user_info.sysCompany
})
const state = reactive({
  loading: false,
  deptData: [],//部门下拉数据
  Notice: [], //表格数据
  searchKey: {
    username: "",
  },
  subtitle: '共 0 名员工',
  nowDept: {},//当前选中部门数据
  datasearchjson: [],//当前缓存构造器
  selectionData: [],//当前选中列表数据
});
// 获取新部门数据
const getnewDeptData = (res) => {
  state.deptData = res
}
const getemitnew = (() => {
  unref(detailsRowRef).colse()
  getDataAll(state.datasearchjson)
  unref(treeDeptRef).getnewDeptData(2)
})
// 删除执行
const delExecute = ((ids) => {
  // console.log('删除执行',ids)
  httpToken({
    method: "post",
    url: '/admin/sys-user/remove',
    data: qs.stringify({
      ids: JSON.stringify(ids)
    })
  }).then(() => {
    getemitnew()
  })
})
//所有选中的用户id
const selectionids = computed(() => {
  return state.selectionData.map(item => {
    return item.userId
  })
})
// 表格选中事件
const selectionChange = (val) => {
  state.selectionData = val
}
// 表格列点击
const rowClick = (row) => {
  unref(detailsRowRef).show(state.nowDept, row)
}
// 搜索点击
const searchClick = () => {
  let searchjson = search_return();
  getDataAll(searchjson)
}
// 当前选中部门改变方法
const DeptChange = (data) => {
  state.nowDept = data
  state.datasearchjson = []
  state.selectionData = []
  getDataAll()
}
const search_return = (() => {
  let jsons = [];
  if (!checkisnull(state.searchKey.username)) {
    jsons.push({ column: "sys_user.chnname", type: "like", value: state.searchKey.username });
  }
  return jsons;
})
// 获取列表数据
const getDataAll = (searchjson = []) => {
  state.loading = true
  let json = [
    { column: "sort", type: 'orderByAsc' },
    { column: "sys_user_company.dept_id", type: 'eq', value: state.nowDept.id }
  ];
  for (let i = 0; i < searchjson.length; i++) {
    json.push(searchjson[i]);
  }
  state.datasearchjson = searchjson;
  httpToken({
    method: "post",
    url: '/admin/sys-user/all',
    data: qs.stringify({
      paramData: JSON.stringify(json)
    }),
  }).then((res) => {
    state.Notice = res.data
    getCompanyNumber()
    // unref(treeDeptRef).getnewDeptData(2)
    state.loading = false;
  }).catch(() => {
    state.loading = false;
  })
}
// 获取公司人数
const getCompanyNumber = () => {
  httpToken({
    method: "post",
    url: '/admin/sys-user/getCompanyManCount',
  }).then((res) => {
    state.subtitle = '共' + res.data + '名员工'
  })
}
// 批量导入获取数据
const batchData = ((data) => {
  console.log('批量导入获取数据', data)
  unref(confirmRef).getData(data, state.nowDept.id)
})
</script>
<style lang="scss" scoped>
@import "@/styles/general/element/table.scss"; //element table 样式重置
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/pagination1.scss"; //element

.centerTop {
  background-color: #fff;
  padding: 20px;
}

.centerbox {
  background-color: #fff;
  padding: 20px;
  height: calc(100vh - 289px);

  .tablebox {
    height: calc(100% - 40px);
    overflow: auto;
  }
}</style>
