<template>
  <el-drawer v-model="state.isshow" size="50%">
    <template #header="{ close, titleId, titleClass }">
      <div class="application">申请记录</div>
    </template>
    <el-table :data="state.Notice" :header-cell-style="{
      background: 'var(--active-alpha)',
      color: config.table.color,
      fontWeight: config.table.fontWeight,
      fontSize: config.table.fontSize,
    }">
      <el-table-column property="applyUser.chnname" :align="'center'" label="姓名" />
      <el-table-column property="applyUser.phone" :align="'center'" label="联系电话" />
      <el-table-column property="applyTime" :align="'center'" label="申请时间" width="160" />
      <el-table-column property="reason" :align="'center'" label="申请理由" />
      <el-table-column property="acchnname" :align="'center'" label="审核人" />
      <el-table-column :align="'center'" label="状态">
        <template #default="scope">
          <span :class="'color'+scope.row.state">{{getisApplyStatus(scope.row.state)}}</span>
        </template>
      </el-table-column>
      <el-table-column :align="'center'" label="操作">
        <template #default="scope">
          <div class="dp-fc" v-if="scope.row.state==0">
            <div class="Delete_span" @click="agreeApply(scope.row.id,1)" v-permission="'system_dept:del'">
              拒绝
            </div>
            <div class="ml-20 editColor cu-p" @click="agreeApply(scope.row.id,0)" v-permission="'system_dept:edit'">
              通过
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-20 dp-f jc-fe">
      <el-pagination @current-change="handleCurrentChange" background layout="prev, pager, next,slot"
        :current-page="currentPage" :total="totalPage">
      </el-pagination>
    </div>
</el-drawer>
</template>

<script setup>
import { reactive, ref } from "vue";
import { httpToken } from "@/utils/request";
import { getisApplyStatus } from "@/utils/server/getcode.js"
import qs from "qs";
const currentPage = ref(1); //当前页数
const totalPage = ref(0); //总条数
const state = reactive({
  loading: false,
  isshow: false,
  Notice: [],
});
const handleCurrentChange = (val) => {
  getlistData(val)
};
// 列表接口数据调取
const getlistData = ((val) => {
  state.loading = true
  let json = [
    { column: "create_date", type: 'orderByDesc' }
  ];
  currentPage.value = val;
  httpToken({
    method: "post",
    url: '/admin/applyjoincompany/list',
    data: qs.stringify({
      page: val,
      paramData: JSON.stringify(json)
    }),
  }).then((res) => {
    // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
    if (val !== res.data.current) {
      getlistData(res.data.current)
      return
    }
    totalPage.value = res.data.total;
    res.data.records.forEach(item => {
      if(item.acceptorUser)
        item.acchnname=item.acceptorUser.chnname
      else
        item.acchnname='/'
    });
    state.Notice = res.data.records
    state.loading = false
  }).catch(() => {
    state.loading = false
  })
})
//是否同意加入公司 id：申请记录id，type：0同意，1拒绝
const agreeApply=((id,type)=>{
  httpToken({
    method: "post",
    url: '/admin/applyjoincompany/agreeApply',
    data: qs.stringify({
      id: id,
      type : type
    }),
  }).then((res) => {
    getlistData(currentPage.value)
  })
})
const show = () => {
  state.isshow = true
  getlistData(1)
}
defineExpose({
  show
});
</script>

<style lang="scss" scoped>
.color0{color: #FF4D4F;}
.color1{color: #52C41A;}
.color2{color: #8C8C8C;}
.application {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.editColor {
  color: #1890ff;
}
</style>
