// 判断是否为构造器可执行
export function checkisnull(str) {
    if (str === 'null' || str == null || str === 'undefined' || str === undefined || str === '') 
        return true
    return false
}
//   判断是否为整数
export function validatenum(num, type) {
    let regName = /[^\d.]/g
    if (type === 1) {
        if (!regName.test(num)) return false
    } else if (type === 2) {
        regName = /[^\d]/g
        if (!regName.test(num)) return false
    }
    return true
}

//   判断是否为小数
export function validatenumord(num, type) {
    let regName = /[^\d.]/g
    if (type === 1) {
        if (!regName.test(num)) return false
    } else if (type === 2) {
        regName = /[^\d.]/g
        if (!regName.test(num)) return false
    }
    return true
}

//   判断是否为空
export function validatenull(val) {
    if (typeof val === 'boolean') {
        return false
    }
    if (typeof val === 'number') {
        return false
    }
    if (val instanceof Array) {
        if (val.length === 0) return true
    } else if (val instanceof Object) {
        if (JSON.stringify(val) === '{}') return true
    } else {
        if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true
        return false
    }
    return false
}

