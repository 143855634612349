<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false" width="500px">
    <template v-if="dialogVisibletitle === '新增用户' || dialogVisibletitle === '修改用户'">
      <div class="dp-f">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
          class="demo-ruleForm w100" label-position="left">
          <el-form-item label="姓名" prop='chnname'>
            <el-input v-model="ruleForm.chnname" :maxlength="config.inputMaxlength"  :disabled="namedisable" show-word-limit placeholder="请输入姓名"/>
          </el-form-item>
          <el-form-item label="手机号" prop='phone' class="is-required">
            <el-input v-model="ruleForm.phone" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入手机号"/>
          </el-form-item>
          <el-form-item label="用户编号" prop='memberNumber'>
            <el-input v-model="ruleForm.memberNumber" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入用户编号"/>
          </el-form-item>
          <el-form-item label="部门" prop='deptId'>
            <el-tree-select v-model="ruleForm.deptId" :data="state.deptData" :render-after-expand="false" check-strictly=true placeholder="请选择部门"></el-tree-select>
          </el-form-item>
          <el-form-item label="岗位" prop='postStr'>
            <el-input v-model="ruleForm.postStr" :maxlength="config.inputMaxlength" show-word-limit placeholder="请输入岗位"/>
            <!-- <el-select
              v-model="ruleForm.post"
              multiple
              placeholder="请选择岗位"
            >
              <el-option
                v-for="item in state.postdata"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select> -->
          </el-form-item>
          <el-form-item label="角色" prop='role'>
            <el-select
              v-model="ruleForm.role"
              multiple
              placeholder="请选择角色"
            >
              <el-option
                v-for="item in state.roledata"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='52' title="确定" CSStype=2 @buttonclick="submitForm()"
          v-model:loading="dialogloading"></oabutton>
        <oabutton class="searcML" width='120' height='52' CSStype=3 title="取消" @buttonclick="dialogVisible = false"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits,computed} from 'vue'
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
import { validEmail, validPhone } from "@/utils/server/validate.js";
import {getstatusData,getroledata,getpostdata,getdeptData} from "@/utils/server/selectdata";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
  deptData:[],//部门下拉数据
  roledata:[],//角色下拉数据
  // postdata:[],//岗位下拉数据
})
// getpostdata().then(res=>{state.postdata=res})
getroledata().then(res=>{state.roledata=res})
getdeptData().then(res=>{state.deptData=res})
let namedisable = computed(() => {
  return dialogVisibletitle.value=="新增用户"?false:true
})
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
    chnname:'',//中文名
    deptId:'',//所属部门id
    memberNumber:'',//用户编号
    phone:'',//手机号
    role:[],//角色id
    postStr:'',//岗位
    // post:[],//岗位id
})
// 联系方式格式校验
const validPhonedata = (rule, value, callback) => {
  if (value === ''||value == null) {
    callback(new Error('请输入手机号'))
  } else {
    if(validPhone(value)){
      callback()
    }else
      callback(new Error('手机号格式错误'))
  }
}
const rules = reactive({//from 表单校验
  chnname: [
    { required: true, message: '请输入姓名', trigger: 'blur' },
  ],
  phone:[
    { validator: validPhonedata, trigger: 'blur' }
  ],
  // role: [
  //   { required: true, message: '至少选择一个角色', trigger: 'blur' },
  // ],
})
// 表单重置
const fromDataReset = (() => {
  objdata_ret(ruleForm)
  ruleForm.role=[]
  // ruleForm.post=[]
})
const add = ((deptId) => {
  getdeptData().then(res=>{state.deptData=res})
  fromDataReset()
  ruleForm.deptId=deptId
  dialogloading.value = false
  dialogVisibletitle.value = '新增用户'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
const editclick = ((row,deptId) => {
  getdeptData().then(res=>{state.deptData=res})
  fromDataReset()
  objdata_set(ruleForm, row)
  ruleForm.role=[]
  row.roleList.forEach(item => {
    if(item.roleId!=2){
      ruleForm.role.push(item.roleId)
    }
  })
  // ruleForm.post=row.postList.map(item=>{
  //   return item.postId
  // })//岗位
  ruleForm.deptId=deptId
  id.value = row.userId
  dialogloading.value = false
  dialogVisibletitle.value = '修改用户'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      if (dialogVisibletitle.value === '新增用户') {
        dataGeneration(ruleForm)
      } else if (dialogVisibletitle.value === '修改用户') {
        let ruleFormjson = {
          userId: id.value,
          ...ruleForm
        }
        dataGeneration(ruleFormjson, '/admin/sys-user/update')
      }
    } else {
      dialogloading.value = false
    }
  })
}
// 数据生成
let dataGeneration = ((json, url = '/admin/sys-user/add') => {
  httpToken({
    method: "post",
    url: url,
    data: json,
  }).then((res) => {
    dialogloading.value = false
    dialogVisible.value = false
    emit('Emit')
  }).catch(() => {
    dialogloading.value = false
  })
})
defineExpose({
  add,
  editclick,
});
</script>