<template>
  <el-dialog v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false" width="560px">
    <div class="batchbox">
      <div class="prompt dp-f ai-c pl-12 pr-12 mb-28">
        <img class="mr-4" src="@/assets/img/general/alarm.png">
        注：一次最多可导入5000名企业成员，若超出请分多次导入
        <!-- <img class="ml-at" src="@/assets/img/general/del.png"> -->
      </div>
      <el-upload ref="uploadRef" class="upload-demo w-384 h-192" drag action="#" :accept='import_accept'
        :http-request="xlsxHttpImport" multiple>
        <img class="mr-4 w-48 h-48 mb-16" src="@/assets/img/general/file.png">
        <div class="w100 ta-c mb-4">将填写完的“企业成员批量数据模版”拖到此处</div>
        <div class="w100 ta-c up">点击上传</div>
      </el-upload>
      <div class="download w100 ta-c">
        先下载<span class="cu-p" @click="download">《企业成员批量数据模版》</span>并按照模版中的格式将信息填写完整后上传
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" height='46' title="取消" CSStype=2 @buttonclick="dialogVisible = false"
          v-model:loading="dialogloading"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, defineEmits } from 'vue'
import { import_accept } from "@/utils/base/accept"
import { readExcel } from "@/utils/server/excelFile.js"
import { handleMessage } from "@/utils/server/confirm.js"
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const emit = defineEmits(['Emit'])
// 模板文件导入
const xlsxHttpImport = ((val) => {
  // console.log('模板文件导入',val)
  readExcel(val.file).then(res => {
    console.log('文件解析成功', res)
    dialogVisible.value = false
    let resData = []
    res.forEach(item => {
      if (item['姓名'] && item['手机号']) {
        resData.push({
          'chnname': String(item['姓名'] || '/'),
          'phone': String(item['手机号'] || '/'),
          'role': String(item['角色'] || '/'),
        })
      }
    })
    if(resData.length==0){
      handleMessage('无符合条件数据')
      return
    }
    emit('Emit', resData)
  })
})
const download = (() => {
  window.open("http://116.63.252.12:8866/group1/M00/00/D9/wKgAXmQudEmAcJl6AAAlkdzagt817.xlsx?attname=企业成员批量数据模版.xlsx")
})
const add = (() => {
  dialogloading.value = false
  dialogVisibletitle.value = '批量导入'
  dialogVisible.value = true
})
defineExpose({
  add,
});
</script>
<style lang="scss" scoped >
.upload-demo {
  margin: 0 auto;
  margin-bottom: 34px;

  ::v-deep .el-upload-dragger:hover {
    border-color: #BAE0FF;
  }

  ::v-deep .el-upload-list {
    display: none;
  }
}

::v-deep .ret_button {
  width: 100% !important;
  border-radius: 4px;
  font-size: 18px;
}

.batchbox {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.88);

  .up {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }

  .download {
    font-size: 14px;
    color: #333333;

    span {
      color: rgba(19, 143, 255, 1);
    }
  }

  .prompt {
    width: 100%;
    height: 40px;
    background: #E6F4FF;
    border-radius: 8px;
    border: 1px solid #BAE0FF;
  }
}
</style>