<template>
  <div class="dp-f">
    <div class="fs-22 lefeBox">{{ title }}</div>
    <div class="fs-14 subtitle dp-fc ml-20">{{ subtitle }}</div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "宁波夏天信息科技有限公司",
    },
    subtitle: {
      type: String,
      default: "共 47 名员工",
    },
  },
});
</script>
<style lang="scss" scoped>
.lefeBox {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.subtitle {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>
